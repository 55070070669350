import { useStaticQuery, graphql } from "gatsby"

const useForms = id => {
  const data = useStaticQuery(graphql`
    query formQuery {
      forms: allDataJson(filter: { optionsItem: { eq: "seamless" } }) {
        nodes {
          allSeamlessForms {
            embedUrl
            seamlessShortcode
            title
          }
        }
      }
    }
  `)

  const allForms = data.forms.nodes[0].allSeamlessForms

  let form = {}

  if (!id) return form

  const find = allForms.find(f => f.seamlessShortcode === id)

  if (find) {
    form = find
  }
  return { form }
}

export default useForms
